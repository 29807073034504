<template>
  <div class="mobile-app">
    <div class="row margin-0 justify-content-center">
      <div :class="$width >= $devices.maxTab ? 'col-10 pad-0' : 'col-12 pad-0'">
        <div class="row margin-0">
          <div :class="$width >= $devices.maxTab ? 'col-6 pad-20 app-detail-blk' : 'col-12 pad-20 app-detail-blk pad-right-0'">
            <span>EBechana for IOS and Android</span>
            <h1 class="margin-bottom-10">Connect to people with EBechana's mobile app</h1>
            <p>EBechana is a online free classified platform to connect a customer to customer based on their requirements. It also helps businesses to create their own store for free. Customer or a Business user can create unlimited ads. Their will be no fee for ads.</p>
            <div class="row margin-0 pad-top-10">
              <div class="col max-fit-content pad-left-0">
                <a
                  href="https://play.google.com/store/apps/details?id=com.codejunkers.ebechana"
                >
                  <img
                    src="/play-store.png"
                    style="max-height: 4rem;"
                    alt=""
                    srcset=""
                  />
                </a>
              </div>
              <div class="col max-fit-content pad-left-0">
                <a
                  href="https://apps.apple.com/us/app/ebechana/id1529085800"
                >
                  <img
                    src="/app-store.png"
                    style="max-height: 4rem;"
                    alt=""
                    srcset=""
                  />
                </a>
              </div>
            </div>
            <div class="pad-top-40">
              <a href="http://ebechana.com" style="border-bottom: 1px dashed #000;color: #000;">Explore ebechana.com</a>
            </div>
          </div>
          <div class="col pad-20" style="padding-top: 10vh"></div>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="false">
      <div
        class="row justify-content-center"
        style="height: 45vh; padding-top: 5vh"
      >
        <div class="col-12 text-center">
          <img
            src="/logo.png"
            style="max-width: 100px; margin-bottom: 2vh"
            alt=""
          /><br />
        </div>
        <div class="col-10 col-md-6 text-center">
          <h2 class="text-center">Sell your Car, Bike, Mobile at best price</h2>
          <p class="text-center">
            Download and enjoy unlimited AD posting experience on your hands.
          </p>
        </div>
        <div class="col-10 col-md-10 text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.codejunkers.ebechana"
          >
            <img
              src="/play-store.png"
              style="max-height: 60px"
              class="img-fluid margin-10"
              alt=""
              srcset=""
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/ebechana/id1529085800"
            class="margin-left-10"
          >
            <img
              src="/app-store.png"
              style="max-height: 60px"
              class="img-fluid margin-10"
              alt=""
              srcset=""
            />
          </a>
        </div>
      </div>
      <div class="row justify-content-center" v-if="false" style="height: 55vh">
        <div class="col-12 pad-0" style="background: #efefef">
          <div class="row margin-0">
            <div class="col-4"></div>
            <div class="col-4"></div>
            <div class="col-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: null,
    };
  },
  created() {
    document.title = "Download App - EBechana";
  },
};
</script>
<style lang="stylus" scoped>
.app-detail-blk {
  padding-top: 10vh;
}
.mobile-app {
  min-height: 100vh;
  background-color: #E3F8FF;
}
</style>
